import React from "react";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { Row, Col } from "antd";
import { getChildrenToRender, isImg } from "@/utils/index";

const children = [
  {
    name: "block0",
    xs: 24,
    md: 6,
    className: "block",
    title: {},
    childWrapper: {
      className: "slogan",
      children: [],
    },
  },
  {
    name: "block1",
    xs: 24,
    md: 6,
    className: "block",
    title: { children: "关于我们" },
    childWrapper: {
      children: [{ name: "link0", href: "#", children: "" }],
    },
  },
  {
    name: "block2",
    xs: 24,
    md: 6,
    className: "block",
    title: { children: "联系我们" },
    childWrapper: {
      children: [
        { href: "#", name: "link0", children: "公司名称：" },
        { href: "#", name: "link1", children: "服务热线：" },
        { href: "#", name: "link2", children: "联系地址：" },
        { href: "#", name: "link3", children: "邮箱：" },
      ],
    },
  },
  {
    name: "block3",
    xs: 24,
    md: 6,
    className: "block",
    title: {},
    childWrapper: {
      children: [],
    },
  },
];

class Footer extends React.Component {
  static defaultProps = {
    className: "footer1",
  };

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === "string" &&
            title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            {childWrapper.children.map(getChildrenToRender)}
          </div>
        </Col>
      );
    });

  getIcp = (icp) => {
    const { host } = window.location;
    if (host.endsWith("home.ayg.ink") || !icp) {
      return "";
    }
    return icp;
  };

  render() {
    const { isMobile, pageData } = this.props;
    const { aboutUs, mobile, email, companyAddress, platformName, icp } =
      pageData;
    const childrenAboutUs = children[1];
    const childrenContactUs = children[2];
    const footer1 = [];
    aboutUs && footer1.push({ name: "link0", href: null, children: aboutUs });
    children.splice(1, 1, {
      ...childrenAboutUs,
      childWrapper: {
        children: footer1,
      },
    });
    const footer2 = [];
    platformName &&
      footer2.push({
        href: null,
        name: "link0",
        children: `公司名称：${platformName}`,
      });
    mobile &&
      footer2.push({
        href: null,
        name: "link1",
        children: `服务热线：${mobile}`,
      });
    companyAddress &&
      footer2.push({
        href: null,
        name: "link2",
        children: `联系地址：${companyAddress}`,
      });
    email &&
      footer2.push({ href: null, name: "link3", children: `邮箱：${email}` });
    children.splice(2, 1, {
      ...childrenContactUs,
      childWrapper: {
        children: footer2,
      },
    });
    const childrenToRender = this.getLiChildren(children);
    return (
      <div className="home-page-wrapper footer1-wrapper">
        <OverPack className="footer1" playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            className="home-page"
          >
            {childrenToRender}
          </QueueAnim>
          <TweenOne
            animation={{ y: "+=30", opacity: 0, type: "from" }}
            key="copyright"
            className="copyright-wrapper"
          >
            <div className="home-page">
              <div className="copyright">
                {isMobile ? (
                  [
                    <p key="copyright">
                      {platformName ? `版权所有： ${platformName}` : ""}
                    </p>,
                    <a
                      href="https://beian.miit.gov.cn/"
                      target="_blank"
                      rel="noopener noreferrer"
                      key="icp"
                    >
                      备案号：{this.getIcp(icp)}
                    </a>,
                  ]
                ) : (
                  <a
                    href="https://beian.miit.gov.cn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {platformName ? `版权所有： ${platformName}` : ""} 备案号：
                    {this.getIcp(icp)}
                  </a>
                )}
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
